import { apiSlice } from "../api/api.slice";

export const feedbackApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAnswer: builder.mutation({
      query: ({ parentUUID, text }) => ({
        url: `/feedback/answer/${parentUUID}`,
        method: "POST",
        body: { text },
      }),
      invalidatesTags: (result, error, arg) => [
        //Invalidates the list of unit questions beyond the current question
        {
          type: "Questions",
          id: arg.parentUUID,
        },
        {
          type: "Questions",
          id: "UnitList",
        },
      ],
    }),
    createQuestion: builder.mutation({
      query: ({ lessonUUID, text, second }) => ({
        url: `/feedback/question/${lessonUUID}`,
        method: "POST",
        body: { text, second },
      }),
      //The question can be create only in the lesson, so I invalidate only the lesson questions
      invalidatesTags: (result, error, arg) => ({
        type: "Questions",
        id: "LessonList",
      }),
    }),
    upsertQuestionReaction: builder.mutation({
      query: ({ questionUUID, value }) => ({
        url: `/feedback/question-reaction/${questionUUID}`,
        method: "POST",
        body: { value },
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: "Questions",
          id: arg.questionUUID,
        },
        {
          type: "Questions",
          id: "UnitList",
        },
      ],
    }),
    upsertLessonFeedback: builder.mutation({
      query: ({ lessonUUID, ...data }) => ({
        url: `/feedback/lesson-feedback/${lessonUUID}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "Feedback", id: "Mine" },
        { type: "Feedback", id: "Classroom" },
        { type: "Enrollments", id: "Selected" },
      ],
    }),
    getStudentLessonDetails: builder.query({
      query: (lessonUUID) => `/feedback/lesson/${lessonUUID}`,
      transformResponse: (response) => response.data,
      invalidatesTags: ["Questions", "Feedback"],
    }),
    getQuestions: builder.query({
      query: ({ lessonUUID, limit = 12, offset = 0, order = "ASC" }) =>
        `/feedback/questions/${lessonUUID}/${limit}/${offset}/${order}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => [
        { type: "Questions", id: "LessonList" },
        ...result.rows.map((question) => ({
          type: "Questions",
          id: question.uuid,
        })),
      ],
    }),
    getUnitQuestions: builder.query({
      query: (unitUUID) => `/feedback/unit-questions/${unitUUID}`,
      transformResponse: (response) => response.data,
      //Don't return a list of questions, just the unit lesson with inside the questions
      providesTags: [{ type: "Questions", id: "UnitList" }],
    }),
    getCourseQuestions: builder.query({
      query: ({
        courseUUID,
        limit = 12,
        offset = 0,
        order = "DESC",
        orderBy = "updatedAt",
      }) =>
        `/feedback/course-questions/${courseUUID}/${limit}/${offset}/${order}/${orderBy}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => {
        console.log("result", result);
        return [
          { type: "Questions", id: "CourseList" },
          ...result.rows.map((question) => ({
            type: "Questions",
            id: question.uuid,
          })),
        ];
      },
    }),
    getFeedback: builder.query({
      query: (lessonUUID) => `/feedback/lesson-feedback/${lessonUUID}`,
      transformResponse: (response) => response.data,
      providesTags: [{ type: "Feedback", id: "Mine" }],
    }),
    getUnitFeedback: builder.query({
      query: (unitUUID) => `/feedback/unit-feedback/${unitUUID}`,
      transformResponse: (response) => response.data,
    }),
    getUnitFeedbackCountPerLesson: builder.query({
      query: (unitUUID) => `/feedback/unit-feedback-count/${unitUUID}`,
      transformResponse: (response) => response.data,
    }),
    getClassroomFeedback: builder.query({
      query: ({
        lessonUUID,
        limit = 100,
        offset = 0,
        order = "DESC",
        orderBy = "updatedAt",
      }) =>
        `/feedback/classroom-feedback/${lessonUUID}/${limit}/${offset}/${order}/${orderBy}`,
      transformResponse: (response) => response.data,
      providesTags: [{ type: "Feedback", id: "Classroom" }],
    }),
    getFeedbackSummaryByLesson: builder.query({
      query: (lessonUUID) => `/feedback/lesson-summary/${lessonUUID}`,
      transformResponse: (response) => response.data,
    }),
    deleteQuestion: builder.mutation({
      query: (questionUUID) => ({
        url: `/feedback/question/${questionUUID}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Questions", id: "CourseList" },
        { type: "Questions", id: "LessonList" },
        { type: "Questions", id: "UnitList" },
      ],
    }),
  }),
});

export const {
  useCreateAnswerMutation,
  useCreateQuestionMutation,
  useUpsertQuestionReactionMutation,
  useUpsertLessonFeedbackMutation,
  useGetFeedbackQuery,
  useGetClassroomFeedbackQuery,
  useGetUnitFeedbackQuery,
  useGetUnitFeedbackCountPerLessonQuery,
  useGetQuestionsQuery,
  useGetUnitQuestionsQuery,
  useGetCourseQuestionsQuery,
  useGetStudentLessonDetailsQuery,
  useGetFeedbackSummaryByLessonQuery,
  useDeleteQuestionMutation,
} = feedbackApiSlice;
