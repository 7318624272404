import React, { useState } from "react";
import { LogContainer as ScrollableContainer } from "../../styles";
import * as FormStyles from "../../../../styles/Forms.styles";
import * as Styles from "../../styles";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetContactMessagesQuery } from "../../../../store/admin/admin.api";

const AdminMessages = () => {
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(undefined);
  console.log(date);
  /* Query hooks */
  const { isSuccess, data: messages } = useGetContactMessagesQuery({
    date: date ? new Date(date).toISOString() : undefined,
  });

  console.log("messages", messages);

  return (
    <div className="p-4 d-flex flex-column flex-1 overflow-hidden">
      <div className="d-flex flex-row gap-4 mb-3 align-items-center">
        <div>Filter by date</div>

        <Form.Group className="position-relative">
          <FormStyles.FormLabel>Date</FormStyles.FormLabel>
          <FormStyles.FormControl
            type="date"
            value={date}
            max={today}
            onChange={(e) => setDate(e.target.value)}
          />
        </Form.Group>
      </div>
      {isSuccess && (
        <ScrollableContainer>
          {messages.length === 0 && !date && <div>No messages found</div>}
          {date && messages.length === 0 && (
            <div>No messages found for this date</div>
          )}
          {messages.map((message) => (
            <MessageCard key={message.uuid} message={message} />
          ))}
        </ScrollableContainer>
      )}
    </div>
  );
};

export default AdminMessages;

const MessageCard = ({ message }) => {
  return (
    <Styles.MessageCard>
      <div className="message-header">
        <div className="d-flex flex-wrap flex-row gap-4 mb-2 align-items-center">
          <div>
            <b>From:</b> {message.email}
          </div>
          <div>
            <b>Date:</b> {new Date(message.createdAt).toLocaleDateString()} at{" "}
            {new Date(message.createdAt).toLocaleTimeString()}
          </div>
        </div>
        <Link
          to={`/admin/messages/${message.uuid}`}
          state={{
            message,
          }}
        >
          <FormStyles.EvoliButton>View</FormStyles.EvoliButton>
        </Link>
      </div>
      <div>
        Subject: <b>{message.subject}</b>
      </div>

      <div>{message.title}</div>
      <div className="text-truncate">{message.message}</div>
      <hr />
    </Styles.MessageCard>
  );
};
