import React, { useContext } from "react";
import { Container, Dropdown, Nav, Navbar as BNavbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import evoliLogoWhite from "../../assets/images/evoliWhite.png";
import i18next from "i18next";
import { ThemeContext } from "../../styles/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentRole,
  selectCurrentUser,
} from "../../store/auth/auth.slice";
import { useLogoutMutation } from "../../store/auth/auth.api";
import { EvoliButton } from "../../styles/Forms.styles";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";

/* Application navbar. It contains the links to the current accessible pages, such as about page, home page, index page and
also a logout button if the user is logged.*/
const Navbar = ({
  sticky = "top",
  fixed = false,
  fluid = true,
  language = false,
  theme = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Queries
  const [logout] = useLogoutMutation();

  //Redux state
  const user = useSelector(selectCurrentUser);
  const role = useSelector(selectCurrentRole);

  //Api handler
  const logoutHandler = () => {
    logout()
      .unwrap()
      .catch((_) => {
        dispatch(logOut());
      });
  };

  return (
    <Styles.Navbar className="px-2" expand="md">
      <Container fluid={fluid}>
        <Styles.SLink to="/" className="text-uppercase">
          <BNavbar.Brand>
            <img src={evoliLogoWhite} alt="EVOLI" />
          </BNavbar.Brand>
        </Styles.SLink>
        <BNavbar.Toggle aria-controls="navbar-content" />
        <BNavbar.Collapse id="navbar-content" className="justify-content-end">
          <Nav>
            {/* Show Admin button only if user has admin role */}

            {user?.role === "admin" && (
              <EvoliButton
                className="d-flex gap-1 align-items-center"
                variant="warning"
                onClick={() => navigate("/admin")}
              >
                <FontAwesomeIcon icon={faUserShield} className="fs-5" />
                <span>Admin</span>
              </EvoliButton>
            )}

            {user && (
              <EvoliButton
                onClick={() =>
                  navigate(
                    role === "teacher"
                      ? "/teacher/home/courses"
                      : "/student/home/enrollments"
                  )
                }
              >
                {role === "teacher" ? "My Teachings" : "My Learning"}
              </EvoliButton>
            )}
            {theme && <ThemeChooser />}
            {language && <LanguageChooser />}
            {!props.hideButtons && (
              <AccountChooser user={user} logoutHandler={logoutHandler} />
            )}
          </Nav>
        </BNavbar.Collapse>
      </Container>
    </Styles.Navbar>
  );
};

const AccountChooser = ({ user, logoutHandler }) => {
  //Translator function
  const { t } = useTranslation("", { keyPrefix: "components.navbar" });
  //Redirect hook
  const navigate = useNavigate();

  /* Logout handler */
  const clickLogoutHandler = () => {
    navigate("/");
    logoutHandler();
  };

  console.log(user);

  return user ? (
    <Dropdown align="end" className="nav-item nav-link">
      <Dropdown.Toggle as={"span"} className="d-flex align-items-center gap-1">
        {/* First of first name and first letter of last name */}
        <Styles.UserIcon>
          {user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}
        </Styles.UserIcon>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          className="d-flex align-items-center gap-1"
          onClick={() => navigate("/account")}
        >
          {t("profile")}
        </Dropdown.Item>
        <Dropdown.Item
          className="d-flex align-items-center gap-1"
          onClick={() => navigate("/account/security")}
        >
          Security
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          className="d-flex gap-1 align-items-center"
          onClick={clickLogoutHandler}
        >
          {t("logout")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <>
      <Nav.Link
        as={Link}
        className="d-flex gap-1 align-items-center"
        to="/login"
      >
        <i className="fas fa-sign-in-alt fs-5" />
        <span>{t("login")}</span>
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/register"
        className="d-flex gap-1 align-items-center"
      >
        <i className="fas fa-user fs-5" />
        <span>Register</span>
      </Nav.Link>
    </>
  );
};

const LanguageChooser = () => {
  //Translator function
  const { t } = useTranslation("", { keyPrefix: "components.navbar" });

  const languages = {
    it: "Italiano",
    en: "English",
  };

  const changeLanguageHandler = (id) => {
    i18next.changeLanguage(id);
    //Update localStorage
    localStorage.setItem("language", id);
  };

  return (
    <Dropdown className="nav-item nav-link">
      <Dropdown.Toggle as={"span"} className="d-flex align-items-center gap-1">
        <i className="clickable fas fa-globe-europe fs-5" />
        {t("language")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.entries(languages).map(([id, name]) => (
          <Dropdown.Item key={id} onClick={() => changeLanguageHandler(id)}>
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ThemeChooser = () => {
  //Translator function
  const { t } = useTranslation("", { keyPrefix: "components.settings" });
  //Theme context
  const { setTheme, themeList } = useContext(ThemeContext);

  return (
    <Dropdown className="nav-item nav-link">
      <Dropdown.Toggle as={"span"} className="d-flex align-items-center gap-1">
        <i className="clickable fas fa-palette fs-5" />
        {t("theme")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(themeList).map((id) => (
          <Dropdown.Item key={id} onClick={() => setTheme(id)}>
            {t(id)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Navbar;

const Styles = {};

Styles.UserIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
  border: 2px solid ${(props) => props.theme.textPrimary};
  border-radius: 50%;
  background: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

Styles.SLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;

  i {
    transform: scale(1.5);
  }
`;

Styles.Navbar = styled(BNavbar)`
  border-style: none;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.textPrimary} !important;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  min-height: 3.5rem;

  .navbar-brand img {
    height: 2.2rem;
  }

  .nav-link {
    color: ${(props) => props.theme.textPrimary} !important;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.3s;
  }

  & .collapse {
    //Under md breakpoint
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  .navbar-nav {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 0.5rem;
    }
  }
`;
